<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<el-collapse-transition>
				<div class="orderbatchtip" v-show="showtype">
					<i class="el-icon-close ordertipclose"  @click="showtype = !showtype"></i>
					<p>1.批量发货只适用于待发货的订单。</p>
					<p>2.单次最多导入1000条。</p>
				</div>
			</el-collapse-transition>
			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<label class="search-label">文件搜索：</label>
							<el-input placeholder="请输入文件名称" v-model="filename"></el-input>
						</div>
						<div class="search-item">
							<label class="search-label">导入时间：</label>
							<el-date-picker v-model="valuetimes" type="datetimerange" :picker-options="pickerOptions" start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
							</el-date-picker>
						</div>
					</div>
					<div class="serach-buttom-button">
					    <el-button type="primary" @click="consignmentsearch">搜索</el-button>
					    <el-button @click="resetting">重置条件</el-button>
						<el-button @click="logisticstype = true">修改物流</el-button>
					</div>
				</div>
			</el-card>
			<div class="orderbatchup">
				<div class="orderbatchupfirst">
					<ul>
						<li class="clearfloat">
							<span class="batchtext"><i>*</i> 导入模板:</span>
							<div class="batchright batchrighted">
								<el-input placeholder="请上传模板" readonly :value="fail.toString()">
								    <el-upload slot="append" class="upload-demo" ref="failUpload" action="" :multiple="false"
								        accept=".xls, .xlsx, .csv" :on-change="handeleOnchagne"  :file-list="fileList"
								        :show-file-list="false" :auto-upload="false">
								        <el-button type="primary">
								            选择文件
								        </el-button>
								    </el-upload>
								</el-input>
							</div>
						</li>
						<li class="clearfloat">
							<span class="batchtext"><i>*</i> 快递公司:</span>
							<div class="batchright">
								<template>
									<el-select v-model="batchdemail" filterable clearable placeholder="请选择快递公司">
										<el-option  v-for="(item,index) in logisticslist" :key="index" :label="item.value" :value="item.value"></el-option>
									</el-select>
								</template>
							</div>
						</li>
					</ul>
				</div>
				<div class="orderbatchuplast clearfloat">
					<button @click="delivery">立即发货</button>
					<a href="javascript:void(0);" @click="downTemplate">下载模板文件</a>
				</div>
			</div>
			<div class="batchtable">
				<div v-if="shipmentlist && shipmentlist.length">
					<div class="batchtablecon">
						<table>
							<thead>
								<tr>
									<th class="tableleft">操作时间</th>
									<th>操作人</th>
									<th>文件名称</th>
									<th>发货单数</th>
									<th>成功发货单数</th>
									<th>失败单数</th>
									<th>操作</th>
								</tr>
							</thead>
							<colgroup>
								<col style="width:15%">
								<col style="width:15%">
								<col style="width:15%">
								<col style="width:15%">
								<col style="width:15%">
								<col style="width:10%">
								<col style="width:15%">
							</colgroup>
							<tbody>
								<tr v-for="(item,index) in shipmentlist" :key="index">
									<td class="tableleft">{{item.created_at}}</td>
									<td>{{item.operate_user_name}}</td>
									<td>{{item.file_name ? item.file_name : '-'}}</td>
									<td>{{item.count}}</td>
									<td>{{item.success_num}}</td>
									<td><span class="defeatcs" @click="failtypetc(item)">{{item.fail_num}}</span></td>
									<td><a href="javascript:void(0);" class="batchdown" @click="batchdownload(item)">下载</a></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tablepage d-flex justify-content-center">
						<div class="orderlastbutright">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="total,sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
				<template v-else>
				  <div class="zntj" v-if="datatype">
				    <img class="img-default" src="../../assets/images/home-empty.png" alt="" />
				    <p>暂无数据</p>
				  </div>
				</template>
			</div>
		</div>
		
		<!-- 修改物流弹层 -->
		<el-dialog title="修改物流" class="logisticstc" :visible.sync="logisticstype">
			<div class="logisticsbox">
				<div class="orderbatchupfirst">
					<ul>
						<li class="clearfloat">
							<span class="batchtext"><i>*</i> 导入模板:</span>
							<div class="batchright batchrighted">
								<el-input placeholder="请上传模板" readonly :value="fails.toString()">
								    <el-upload slot="append" class="upload-demo" ref="failUpload" action="" :multiple="false"
								        accept=".xls, .xlsx, .csv" :on-change="handeleOnchagned" :limit="1" :file-list="fileListed"
								        :show-file-list="false" :auto-upload="false">
								        <el-button type="primary">
								            选择文件
								        </el-button>
								    </el-upload>
								</el-input>
							</div>
						</li>
						<li class="clearfloat">
							<span class="batchtext"><i>*</i> 快递公司:</span>
							<div class="batchright">
								<template>
									<el-select v-model="batchdemailed" filterable clearable placeholder="请选择快递公司">
										<el-option v-for="(item,index) in logisticslist" :key="index" :label="item.value" :value="item.value"></el-option>
									</el-select>
								</template>
							</div>
						</li>
					</ul>
				</div>
				<div class="logisticstip">
					<p>1、最大支持1000条记录（支持CSV、Xls、xlsx，文件大小请控制在1MB以内）</p>
					<p>2、仅支持对24小时内自己联系物流或者表格导入发货的已发货快递修改一次运单信息</p>
					<p>3、批量发货暂不支持一个订单部分发货</p>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="logisticstype = false">取 消</el-button>
				<el-button @click="deliverytc" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 失败单数弹层 -->
		<el-dialog title="失败订单信息" class="failtc" :visible.sync="failtype">
			<div class="failbox">
				<div class="failtitle clearfloat">
					<p>订单号</p>
					<p>失败原因</p>
				</div>
				<div class="failboxcon">
					<div class="failboxlist clearfloat" v-for="(item,index) in failtypelist" :key="index">
						<p>{{item.orderId}}</p>
						<p>{{item.errorInfo}}</p>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="failtype = false">取 消</el-button>
				<el-button @click="failtype = false" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				datatype: false,
				showtype: true,
				filename:"",//文件名称
				valuetimes:'',//日期选择
				batchdemail:'',//快递
				batchdemailed:'',//快递
				logisticstype: false,//是否显示修改物流
				currentPage: 1,
				eachPage:10,
				total_entry: 0,
				fail: [],
				fails: [],
				logisticslist:[],
				shipmentlist:[],
				fileList: [],
				fileListed: [],
				failtype: false,//是否显示失败单数弹层
				failtypelist:[],
				failurl:'',//模板下载
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '7天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '30天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			};
		},
		beforeCreate() {
			this.$store.commit("commonInfo", "");
		},
		created() {
			let data = {
				keys: ["batch.shipment.import.template"]
			}
			this.common.getCommonInfo(this, data);
		},
		computed: {
			common_info: {
				get() {
					let stateuser = {
						'batch.shipment.import.template': [],
					}
					let val = this.$store.state.common_info || stateuser;
					return val
				},
				set() { }
			}
		},
		mounted() {
			this.shipmentlistapi();
			this.logisticsapi();
		},
		methods: {
			logisticsapi() {
				let data = {
					source: 4
				};
				this.$get(this.$apis.logistics, data).then(res => {
					if (res.code == 200) {
						this.logisticslist = res.data;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 下载模板文件
			downTemplate() {
				let downurl = this.common_info['batch.shipment.import.template'];
			    if (!downurl) {
			        this.$message.error('缺少必要参数"模板下载路径"')
			        return false;
			    }
			    window.parent.open(downurl, '_self');
			},
			batchdownload(i){
				this.$get(this.$apis.batchdownload + i.id).then(res => {
					if (res.code == 200) {
						window.parent.open(res.data.result, '_self');
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			handeleOnchagne(file, fileList) {
			    this.fail = [file['name']];
			    this.fileList = fileList;
			},
			handeleOnchagned(file, fileList) {
			    this.fails = [file['name']];
			    this.fileListed = fileList;
			},
			shipmentlistapi() {
				this.datatype = false;
				let data = {
					search: [],
					order: [{
						key: "id",
						value: "desc"
					}],
					page: this.currentPage,
					limit: this.eachPage
				};
				if (this.filename) { //文件名称
					let obj = {"key": 'file_name',"value": this.filename,"op": "search"}
					data.search.push(obj);
				}
				if (this.valuetimes) { //时间筛选
					let obj = {
						"key": "created_at",
						"value": [this.common.timeStampTurnTime(this.valuetimes[0]), this.common.timeStampTurnTime(this.valuetimes[1])],
						"op": "between"
					}
					data.search.push(obj);
				}
				this.$get(this.$apis.consignmentlist, data).then(res => {
					if (res.code == 200) {
						this.shipmentlist = res.data.list;
						this.total_entry = res.data.total;
						this.datatype = true;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 搜索
			consignmentsearch() {
				this.currentPage = 1;
				this.shipmentlistapi();
			},
			// 重置
			resetting() {
				this.filename = '';
				this.valuetimes = '';
				this.shipmentlistapi();
			},
			handleSizeChange(val) {
				this.eachPage = val;
				this.shipmentlistapi();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.common.backTop();
				this.shipmentlistapi();
			},
			delivery(){
				if (!this.fail.length) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请上传模板",
					});
					return false;
				}
				if (!this.batchdemail) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请选择物流公司",
					});
					return false;
				}
				let that=this;
				let formData = new FormData();
				formData.append("file", this.fileList[0].raw);
				formData.append("express", this.batchdemail);
				let config = {
					headers: {
						"Content-Type": "mutipart/form-data",
					},
				};
				// console.log(formData,this.fileList[0].raw);
				// return false
				this.$post(this.$apis.deliveryapi, formData, config).then(res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							offset: 200,
							duration: 1000,
							message: "发货成功",
						});
						this.fileList = [];
						this.fail="";
						this.batchdemail = '';
						this.shipmentlistapi();
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 修改发货
			deliverytc(){
				if (!this.fails.length) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请上传模板",
					});
					return false;
				}
				if (!this.batchdemailed) {
					this.$message({
						type: "warning",
						offset: 200,
						duration: 1000,
						message: "请选择物流公司",
					});
					return false;
				}
				let that=this;
				let formData = new FormData();
				formData.append("file", this.fileListed[0].raw);
				formData.append("express", this.batchdemailed);
				let config = {
					headers: {
						"Content-Type": "mutipart/form-data",
					},
				};
				this.$post(this.$apis.deliveryapixg, formData, config).then(res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							offset: 200,
							duration: 1000,
							message: "发货成功",
						});
						this.logisticstype = false;
						this.fileListed = [];
						this.fails="";
						this.batchdemailed = '';
						this.shipmentlistapi();
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			//失败商品原因
			failtypetc(list){
				this.failtypelist = list.error_data;
				this.failtype = true;
			}
		}
	};
</script>
<style scoped>
	@import url("css/order.css");
	.batchtext i{display: inline-block;font-size: 14px;color: #f1495c;}
	.failboxlist p:nth-child(1){height: 50px;}
	.failboxlist p:nth-child(2){height: 50px;}
	.orderbatchup{margin-top: 20px;}
	.batchrighted /deep/ .el-input{width: 360px;}
	.batchright /deep/ .el-select > .el-input{width: 100%;}
	.batchright /deep/ .el-input__inner{height: 40px;}
	.orderbatchuplast button{cursor: pointer;}
	.zntj{width: 100%;padding: 40px 0;box-sizing: border-box;}
	.zntj img{display: block;max-width: 180px;margin: 0 auto 10px;}
	.zntj p{font-size: 14px;color: #666;text-align: center;}
</style>